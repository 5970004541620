<template>
	<div
		v-if="isVisible"
		class="toast-class"
		v-bind:class="`${variant} ${classAnimation}`"
		@click="handdlerToast()"
	>
		<InfoIcon class="mr-2"/>
		<div class="d-block no-break">
			<span>{{ message }}</span><br>
			<span>{{ message1 }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Toast",

		data () {
			return {
				classAnimation: null,
				toastVariant: null,
				isVisible: null
			};
		},

		props: {
			message: {
				type: String,
				default: "Erro de conexão com o servidor!"
			},
			message1: {
				type: String,
				default: "Telefone: +55(66) 99933-1539 email: informaticasgcv@gmail.com"
			},
			visible: {
				type: Boolean,
				required: true
			},
			variant: {
				type: String,
				required: false
			},
			onlyWatch: {
				type: Boolean,
				default: false
			},
			toast_align: {
				type: String,
				required: false,
				default: "up"
			},
			hideOnClick: {
				type: Boolean,
				default: false
			},
			showAfterHide: {
				type: Boolean,
				default: false
			}
		},

		watch: {
			visible: "getAlertStatus"
		},

		mounted () {
			if (!this.onlyWatch)
				this.getAlertStatus();
		},

		methods: {
			getAlertStatus () {
				if (this.visible) {
					this.isVisible = true;
					setTimeout(() => {
						if (this.toast_align === "right")
							this.classAnimation = "slide-in-right";
						else if (this.toast_align === "up")
							this.classAnimation = "slide-in-up";

					}, 1000);
				} else {
					if (this.toast_align === "right")
						this.classAnimation = "slide-out-right";
					else if (this.toast_align === "up")
						this.classAnimation = "slide-out-up";
					setTimeout(() => {
						this.isVisible = false;
					}, 1000);
				}
			},

			handdlerToast () {
				this.$emit("clickToast");

				if (this.hideOnClick) {
					if (this.toast_align === "right")
						this.classAnimation = "slide-out-right";
					else if (this.toast_align === "up")
						this.classAnimation = "slide-out-up";

					setTimeout(() => {
						this.isVisible = false;
					}, 1000);
				}

				if (this.showAfterHide) {
					setTimeout(() => {
						this.getAlertStatus();
					}, 300000);
				}


			}
		}
	};
</script>

<style scoped>
	.toast-class {
		top: 6px;
		height: 3rem;
		background-color: #0377a5;
		border-radius: 0 0 10px 10px;
		font-size: 12px;
		display: flex;
		font-family: "Roboto Condensed Regular";
		letter-spacing: -0.05em;
		color: #FFFFFF;
		align-items: center;
		padding: 10px;
		position: fixed;
		transition: all 1s ease;
		opacity: 0;
		z-index: 999;
		max-width: 96%;
		cursor: pointer;
	}

	.right {
		right: 0px !important;
	}

	/*Toats entrando por la derecha*/
	.toast-class.slide-out-right {
		transform: translateX(100%);
	}

	.toast-class.slide-in-right {
		transform: translateX(0);
		opacity: 1;
	}

	/*Toats entrando desde arriba*/
	.toast-class.slide-out-up {
		transform: translateY(0);
	}

	.toast-class.slide-in-up {
		transform: translateY(100%);
		opacity: 1;
	}

	.error {
		background: #C12332 !important;
	}

	.warning {
		background: #ee850d !important;
	}

	.info {
		background: #6dcff6 !important;
	}

	.success {
		background: #28A745 !important;
	}

	span {
		margin-left: 8px;
	}

	.no-break {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (min-width: 600px) {
		.toast-class {
			height: 5rem;
			font-size: 20px;
			top: -2.1rem;
		}
	}
</style>
