<script>
	export default {
		name: "LabelDay",
		component: {
		},

		props: {
			options: {
				type: Object,
				required: false
			},
			color: {
				type: String,
				default: "black"
			},
			backColor: {
				type: String,
				default: "rgb(200, 198, 198, 1)"
			},
			cursor: {
				type: String,
				default: "default"
			}
		},

		computed: {
			labelDayStyle () {
				return {
					background: this.options.backColor || this.backColor,
					color: this.options.color || this.color,
					cursor: this.options.cursor || this.cursor
				};
			}
		}
	};


</script>

<template>
    <div
      class="tituloDiasSemana"
      :style="labelDayStyle"
    >
      <span>{{ options.label }}</span>
    </div>
</template>

<style scoped>
.tituloDiasSemana {
    text-align: center;
    border-color: black;
    border-width: 1px;
    border-style: outset;
    margin-left: -1px;
    z-index: 1;
    font-size: 1rem;
}

@media (max-width: 720px) {
	.tituloDiasSemana {
		font-size: 0.9rem;
	}
}

@media (max-width: 370px) {
	.tituloDiasSemana {
		font-size: 0.8rem;
	}
}

@media (max-width: 325px) {
	.tituloDiasSemana {
		font-size: 0.7rem;
	}
}

@media (max-width: 310px) {
	.tituloDiasSemana {
		font-size: 0.6rem;
	}
}
</style>