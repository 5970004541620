<script>
	import { CalendarIcon } from "vue-feather-icons";
	import IconWithout from "./icons/IconWithout.vue";

	export default {
		components: {
			IconWithout
		},

		name: "ShowDay",
		props: {
			options: {
				type: Object,
				required: false
			},
			color: {
				type: String,
				default: "black"
			},
			backColor: {
				type: String,
				default: "rgb(245, 255, 255)"
			},
			colorTextDay: {
				type: String,
				default: "black"
			},
			cursor: {
				type: String,
				default: "pointer"
			}
		},

		computed: {
			dayStyleBackGround () {
				return {
					background: this.options.backColor || this.backColor,
					cursor: "pointer"
				};
			},

			dayStyleText () {
				return {
					color: this.options.colorTextDay || this.colorTextDay
				};
			},

			dayStyle () {
				return {
					color: this.options.color || this.color
				};
			}
		},

		methods: {
			clickShowDay () {
				this.$emit("clickShowDay");
			}
		}
	};
</script>

<template>
  <div
    class="theme"
    v-if="options.show"
    :style="dayStyleBackGround"
	@click="clickShowDay"
  >
    <CalendarIcon
      v-if="options.today"
	  class="icon-today"
      :color="this.options.colorTextDay || 'red'"
    />
    <IconWithout
      v-else
      class="icon-without"
    />
    <h3 :style="dayStyleText">{{ options.day }}</h3>
    <h2 :style="dayStyle">{{ options.value }}</h2>
  </div>
</template>

<style scoped>
.theme {
	text-align: center;
	border-color: black;
	border-width: 1px;
	border-style: outset;
	width: auto;
	margin-left: -1px;
	margin-top: -1px;
	height: 5.5rem;
	padding-top: 0;
}

.icon-today {
    width: 1.125rem;
	margin: -0.25rem 0 0 -70%
}

.icon-without {
	margin-left: -70%;
	margin-top: -0.25rem;
}

h2 {
	margin-top: 0.1rem;
	font-size: 1.6rem;
	font-weight: bold;
}

h3 {
	margin-top: -0.8rem;
	font-size: 1.3rem;
	font-weight: bold;
}

@media only screen and (max-width: 720px) {
	.theme {
		height: 4.5rem !important;
	}

	h2 {
		margin-top: 0.1rem;
		font-size: 1.4rem;
		font-weight: bold;
	}

	h3 {
		margin-top: -1rem;
		font-size: 1.2rem;
		font-weight: bold;
	}

}

@media only screen and (max-width: 600px) {
	.icon-without {
		margin-top: -0.45rem !important;
	}

	.theme {
		height: 4.2rem !important;
		padding-top: 1px;
	}

	.icon-today {
	    width: 1.1rem;
		margin: -0.45rem 0 0 -70%
	}

	h2 {
		margin-top: 0.1rem;
		font-size: 1.3rem;
		font-weight: bold;
	}

	h3 {
		margin-top: -1rem;
		font-size: 1.1rem;
		font-weight: bold;
	}

}

@media only screen and (max-width: 500px) {
	.icon-without {
		margin-top: -0.25rem !important;
	}

	.icon-today {
	    width: 0.9rem;
		margin: -0.25rem 0 0 -70%
	}

	.theme {
		height: 3.8rem !important;
		padding-top: 1px;
	}

	h2 {
		margin-top: 0.1rem;
		font-size: 1.1rem;
		font-weight: bold;
	}

	h3 {
		margin-top: -1rem;
		font-size: 0.9rem;
		font-weight: bold;
	}

}

@media only screen and (max-width: 370px) {
	.icon-without {
		margin-top: -0.25rem !important;
	}

	.icon-today {
	    width: 0.8rem;
		margin: -0.25rem 0 0 -70%
	}

	.theme {
		height: 3.2rem !important;
		padding-top: 0px;
	}

	h2 {
		margin-top: 0.1rem;
		font-size: 0.95rem;
		font-weight: bold;
	}

	h3 {
		margin-top: -1rem;
		font-size: 0.75rem;
		font-weight: bold;
	}

}

@media only screen and (max-width: 325px) {
	.icon-without {
		margin-top: -0.25rem;
	}

	.icon-today {
	    width: 0.7rem;
		margin: -0.25rem 0 0 -70%
	}

	.theme {
		height: 3rem !important;
		padding-top: 0px;
	}

	h2 {
		margin-top: 0.1rem;
		font-size: 0.85rem;
		font-weight: bold;
	}

	h3 {
		margin-top: -1.1rem;
		font-size: 0.65rem;
		font-weight: bold;
	}

}

@media only screen and (max-width: 310px) {
	.icon-without {
		margin-top: -0.5rem !important;
	}

	.icon-today {
	    width: 0.6rem;
		margin: -0.5rem 0 0 -70%
	}

	.theme {
		height: 2.75rem !important;
		padding-top: 0px;
	}

	h2 {
		margin-top: 0.1rem;
		font-size: 0.75rem;
		font-weight: bold;
	}

	h3 {
		margin-top: -1.2rem;
		font-size: 0.55rem;
		font-weight: bold;
	}

}
</style>