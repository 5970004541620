<script>
	export default {
		name: "TitleMonth",
		component: {
		},

		props: {
			options: {
				type: Object,
				required: false
			},
			color: {
				type: String,
				default: "black"
			},
			backColor: {
				type: String,
				default: "rgb(200, 198, 198, 1)"
			},
			cursor: {
				type: String,
				default: "default"
			},
			border: {
				type: String,
				default: "solid 1px rgb(200, 198, 198, 1)"
			}
		},

		computed: {
			labelTitleStyle () {
				return {
					background: this.options.backColor || this.backColor,
					color: this.options.color || this.color,
					cursor: this.options.cursor || this.cursor,
					border: this.options.border || this.border
				};
			}
		}
	};


</script>

<template>
    <div
      class="tituloMes"
      :style="labelTitleStyle"
    >
      <span>{{ options.label }}</span>
    </div>
</template>

<style scoped>
.tituloMes {
	display: flex;
	justify-content: center;
	align-items: center;
	border-color: white;
	border-width: 1px;
	border-style: outset;
	z-index: 0;
	font-size: 1.7rem;
}

@media (max-width: 720px) {
	.tituloMes {
		font-size: 1.6rem;
	}
}

@media (max-width: 370px) {
	.tituloMes {
		font-size: 1.4rem;
	}
}

@media (max-width: 325px) {
	.tituloMes {
		font-size: 1.2rem;
	}
}

@media (max-width: 310px) {
	.tituloMes {
		font-size: 1rem;
	}
}

</style>