<template>
	<div>
		<div
			v-if="showHideButton"
			class="div-icon"
		>
			<ChevronUpIcon
				v-if="show"
				color="var(--cal-header-color)"
				class="button-arrow"
				@click="handleShowTotales"
			/>
			<ChevronDownIcon
				v-else
				color="var(--cal-header-color)"
				class="button-arrow"
				@click="handleShowTotales"
			/>
		</div>
		<div
			v-if="show && options && options.vencidos"
			class="div-totales"
		>
			<div
				class="main-totales"
				v-bind:class="showAccountBalance ? 'main-totales-grid-columns-5' : ''"
			>
				<h2 class="bold head"></h2>
				<h2 class="bold head"></h2>
				<h2 class="derecha bold head">Receitas</h2>
				<h2 class="derecha bold head">Pagamentos</h2>
				<h2 class="derecha bold head" v-if="showAccountBalance">Saldo Contas</h2>

				<h2 class="rojo">Vencidos</h2>
				<h2 class="rojo">:</h2>
				<h2 @click="findToRecords('ToReceive', 'expired', options.vencidos.receive)" class="rojo derecha cursor-hand">{{ options.vencidos.receive }}</h2>
				<h2 @click="findToRecords('PaymentSchedule', 'expired', options.vencidos.pay)" class="rojo derecha cursor-hand">{{ options.vencidos.pay }}</h2>
				<h2 class="rojo derecha" v-if="showAccountBalance"></h2>

				<h2>À Vencer</h2>
				<h2>:</h2>
				<h2 @click="findToRecords('ToReceive', 'toExpire', options.aVencer.receive)" class="derecha cursor-hand">{{ options.aVencer.receive }}</h2>
				<h2 @click="findToRecords('PaymentSchedule', 'toExpire', options.aVencer.pay)" class="derecha cursor-hand">{{ options.aVencer.pay }}</h2>
				<h2 class="derecha" v-if="showAccountBalance"></h2>

				<h2>Pago</h2>
				<h2>:</h2>
				<h2 @click="findToRecords('ToReceive', 'paid', options.pagados.receive)" class="derecha cursor-hand">{{ options.pagados.receive }}</h2>
				<h2 @click="findToRecords('PaymentSchedule', 'paid', options.pagados.pay)" class="derecha cursor-hand">{{ options.pagados.pay }}</h2>
				<h2 class="derecha" v-if="showAccountBalance"></h2>

				<h2 class="bold">Total</h2>
				<h2 class="bold">:</h2>
				<h2 class="derecha bold">{{ options.mes.receive }}</h2>
				<h2 class="derecha bold">{{ options.mes.pay }}</h2>
				<h2 class="derecha bold cursor-hand"
					v-if="showAccountBalance"
					@click="viewAccountBalance"
				>
					{{ options.mes.balance }}
				</h2>
			</div>
		</div>
		<b-modal
			ref="modalRecords"
			hide-footer
			:hide-header="hideHeaderModal"
			:title="titleModalRecords"
			size="xl"
			no-overflow
			hide-no-focus
		>
			<div v-if="hideHeaderModal" class="title-modal">
				<div class="title-text-modal">{{titleModalRecords}}</div>
				<div
					class="float-right btn-hover-modal"
					@click="closeModal"
				>
					<xIcon/>
				</div>
			</div>
			<div>
				<div class="mt-5 mb-3">
				</div>
				<div v-if="linhas.length" class="table-responsive">
					<DataTable v-if="!isMobile"
						class="data-table"
						:loading="isLoading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:nosearch="true"
						:nofilters="true"
						name="records"
						:hasPagination="true"
						:noedit="true"
						:acoes="false"
						:selectItem="false"
						:globalAcoes="false"
						:alignColumns="alignColumnsTable"
						scroll
						:state="{
							sortBy: sortTable.sortBy,
							sortAsc:  sortTable.order,
							query: ''
						}"
						:async="false"
					/>
					<CardTransaction v-else
						:loading="isLoading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:acoes="false"
						:selectItem="false"
						:globalAcoes="false"
						:colsDataCard="colsDataCard"
						:showSelectItem="false"
					/>
				</div>
				<div v-else class="alert alert-warning" role="alert">
					{{ errMsg }}
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { RecordsService } from "@/services/records";
	import dayjs from "dayjs";
	import CardTransaction from "@/components/CardTransaction.vue";
	import DataTable from "@/components/DataTable.vue";
	import { numberFormatted, formatterSimple } from "@/helpers/common";

	export default {
		name: "TotalsMonth",
		components: {
			CardTransaction,
			DataTable
		},

		data () {
			return {
				recordsService: new RecordsService(),
				linhas: [],
				colunas: [
					"Conta",
					"Categoria",
					"Data",
					"Valor",
					"Referência",
					"Observações"
				],
				colsDataCard: {
					0: {
						idx: 0,
						label: "Conta",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					1: {
						idx: 1,
						label: "Categoria",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					2: {
						idx: 2,
						label: "Data",
						textAlign: "center",
						cols: 3,
						visible: true
					},
					3: {
						idx: 3,
						label: "valor",
						textAlign: "right",
						cols: 3,
						visible: true
					},
					4: {
						idx: 4,
						label: "Referência",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					5: {
						idx: null,
						label: "",
						textAlign: "left",
						cols: 3,
						visible: false
					},
					6: {
						idx: 5,
						label: "Observações",
						textAlign: "left",
						cols: 12,
						visible: true
					},
					7: {
						idx: null,
						label: "",
						textAlign: "left",
						cols: 6,
						visible: false
					}

				},
				alignColumnsTable: [
					"left",
					"left",
					"left",
					"right",
					"left",
					"left"
				],
				categoryExpenses: null,
				errMsg: "Nenhum registro cadastrado!",
				sortTable: {
					sortBy: 2,
					order: -1
				},
				isMobile: false,
				titleModalRecords: "",
				hideHeaderModal:  true,
				categoryExpense: []
			};
		},

		props: {
			options: {
				type: Object,
				required: false
			},
			show: {
				type: Boolean,
				required: false,
				default: false
			},
			showAccountBalance: {
				type: Boolean,
				required: false,
				default: false
			},
			isLoading: {
				type: Boolean,
				required: false,
				default: false
			},
			showHideButton: {
				type: Boolean,
				required: false,
				default: true
			},
			date: {
				type: Date,
				required:  false
			}
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			handleShowTotales () {
				this.$emit("click");
			},

			viewAccountBalance () {
				this.$router.push({ path: "/report/AccountBalance"});
			},

			async findToRecords (type, status, value) {
				const reportTypes = {
					ToReceive: {
						expired: "Contas a receber vencidas",
						toExpire: "Contas a receber",
						paid: "Contas recebidas"
					},
					PaymentSchedule: {
						expired: "Contas a pagar vencidas",
						toExpire: "Contas a pagar",
						paid: "Contas pagas"
					}
				};
				this.titleModalRecords = reportTypes[type][status];


				if (value === "0,00") return;

				switch (type) {
					case "PaymentSchedule":
						this.colunas[0] = "Conta";
						this.colsDataCard[0].label = "Conta";
						break;
					case "ToReceive":
						this.colunas[0] = "Cliente";
						this.colsDataCard[0].label = "Cliente";
						break;
				}

				const month = this.date.getMonth();
				const year = this.date.getFullYear();
				const dateNextMonth = new Date(year, month + 1, 1);
				dateNextMonth.setDate(dateNextMonth.getDate() - 1);
				const initDate = dayjs(new Date(year, month, 1)).format("YYYY-MM-DD");
				const endDate = dayjs(new Date(dateNextMonth)).format("YYYY-MM-DD");
				const params = {
					model: type === "ToReceive" && status === "paid" ? "Transaction" : type,
					status,
					initDate,
					endDate
				};
				const records = await this.recordsService.getRecordsByParams(params);
				this.linhas = records.rows.map(rec => ({
					id: rec.idPaymentSchedule || rec.idToReceive,
					selected: false,
					cols: [
						rec?.toReceive?.customer?.description || rec?.accountSchedule?.description || rec?.customer?.description || "N/A",
						rec?.categoryExpense?.description || rec?.categoryReceive?.description || rec?.categoryAccount?.description || "N/A",
						formatterSimple(rec?.toReceive?.receivedDate || rec.paymentedDate || rec.paymentDate || rec.receivedDate || rec.toReceiveDate),
						numberFormatted(rec?.toReceive?.receivedAmount || rec.paymentAmount || rec.receivedAmount || rec.amount ),
						rec.reference || "",
						rec?.toReceive?.comments || rec.comments
					]
				}));
				this.$refs.modalRecords.show();
			},

			closeModal () {
				this.$refs.modalRecords.hide();
			}
		}
	};
</script>

<style scoped>
@keyframes vertical-slide {
	0% {
		transform: translateY(0);
		opacity: 0.5;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.div-icon {
	text-align: center;
	margin-top: -20px;
}
.div-totales {
	display: flex;
	justify-content: center;
	animation: vertical-slide 500ms;
}

h2 {
  font-size: 1.4rem;
}

.main-totales {
	display: grid;
	width: 35rem;
	grid-template-columns: 5rem 2px 1fr 1fr;
	column-gap: 10px;
	background: azure;
	border-style: solid;
	border-color: var(--color-button-grey-hover);
	border-width: 1px;
	padding: 8px 8px 0 8px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	background-color: white;
	margin-bottom: 2px;
	margin-top: 2px;
}

.main-totales-grid-columns-5 {
	width: 40rem;
	grid-template-columns: 5rem 2px 1fr 1fr 1fr !important;
}

.cursor-hand {
	cursor: pointer;
}

.col-1 {
	padding-right: 8px;
}

.rojo {
	color: red;
}

.derecha {
	text-align: right;
}

.bold {
	font-weight: 600;
	background-color: rgb(215, 238, 247);
	margin-left: -8px;
	padding: 6px 8px 0 8px;
	width: calc(100% + 16px);
	margin-bottom: 0px;
}

.head {
	margin-top: -10px;
	padding-bottom: 5px;
}

.button-arrow {
	width: 40px;
	height: 30px;
	border-radius: 8px;
	background: var(--color-button-grey);
	border-style: solid;
	border-color: var(--color-button-grey-hover);
	border-width: 1px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}

.button-arrow:hover {
  background: var(--color-button-grey-hover);
}

@media (max-width: 720px) {
	.main-totales {
		width: 100%
	}

	h2 {
		font-size: 1.15rem;
	}
}

@media (max-width: 370px) {
	.main-totales {
		width: 100%
	}

	h2 {
		font-size: 0.9rem;
	}
}

@media (max-width: 325px) {
	.main-totales {
		width: 100%
	}

	h2 {
		font-size: 0.8rem;
	}
}

@media (max-width: 310px) {
	.main-totales {
		width: 100%
	}

	h2 {
		font-size: 0.7rem;
	}
}
</style>
